import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthenticationGuard} from './app.guard';
import {UserActivationComponent} from './user-activation/user-activation.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';


const routes: Routes = [
  {
    path: 'connexion',
    component: LoginComponent
  }, {
    path: 'mot-de-passe-oublie',
    component: ForgottenPasswordComponent
  }, {
    path: 'activation/:activationToken',
    component: UserActivationComponent
  }, {
    path: 'reinitialisation-mdp/:activationToken',
    component: PasswordResetComponent
  }, {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
