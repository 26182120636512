import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { UserService } from '../shared/service/user.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {

  submitting = false;
  form: FormGroup;
  sendMailSuccess = false;
  sendMailFailure = false;

  constructor(route: ActivatedRoute, private userService: UserService) {
    this.form = new FormGroup({
      mail: new FormControl('', [Validators.required]),
    });
  }

  onSubmit = async () => {
    this.submitting = true;
    try {
      await this.userService.sendPasswordResetMail({
        mail: this.form.value.mail
      });
      this.sendMailSuccess = true;
    } catch (err) {
      this.sendMailFailure = true;
    } finally {
      this. submitting = false;
    }
  }

  ngOnInit(): void {
  }

}
