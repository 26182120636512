import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public loading = new BehaviorSubject(false);

  constructor(private snackBar: MatSnackBar) {
  }

  private showSnack = (message: string, panelClass: string) => {
    this.snackBar.open(message, 'Masquer', {
      verticalPosition: 'top',
      duration: 3000,
      panelClass
    });
  }

  showSuccessSnack = (message: string) => this.showSnack(message, 'snackbar--success');
  showFailureSnack = (message: string) => this.showSnack(message, 'snackbar--failure');
}
