import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { UserService } from '../shared/service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitting = false;
  form: FormGroup;

  constructor(private appService: AppService, private userService: UserService, private router: Router) {
    this.form = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
  }

  onSubmit = async () => {
    this.submitting = true;
    try {
      const {username, password} = this.form.value;
      const authenticated = await this.userService.authenticate(username, password);
      if (authenticated) {
        this.appService.showSuccessSnack('Authentification réussie.');
        this.router.navigate(['dossiers']);
      } else {
        this.appService.showFailureSnack('Authentification échouée, veuillez ré-essayer.');
        this.form.reset();
      }
    } catch (e) {
      this.appService.showFailureSnack(`Erreur d'authentification : ${e.message}`);
    } finally {
      this.submitting = false;
    }
  }
}
