import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { UserInfo } from '../model/user.model';
import { UserActivationResource } from '../../user-activation/user-activation.model';
import { ForgottenPasswordResource } from '../../forgotten-password/forgotten-password.model';
import { PasswordResetResource } from '../../password-reset/password-reset.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly path = '/api/user';
  public _userInfo: Promise<UserInfo> | null;
  private sessionCountdown: any | null;

  constructor(private httpClient: HttpClient, private router: Router) { }

  get userInfo() {
    if (!this._userInfo) {
      this._userInfo = this.fetchUserInfo();
    }
    return this._userInfo;
  }

  authenticate = async (username: string, password: string): Promise<boolean> => {
    try {
      await this.httpClient.post<void>('/authenticate', { username, password})
        .toPromise();
      return true;
    } catch (e) {
      if (e.status === 401) {
        return false;
      }
      throw e;
    }
  }

  private handleSessionTimeout = () => {
    this._userInfo = null;
    this.router.navigate(['connexion']);
  }

  handleSuccessfulRequest = () => {
    this.startSessionCountdown();
  }

  startSessionCountdown = () => {
    if (this.sessionCountdown) {
      clearTimeout(this.sessionCountdown);
    }
    this.sessionCountdown = setTimeout(this.handleSessionTimeout, 20 * 60 * 1000);
  }

  isAuthenticated = async (): Promise<boolean> => {
    try {
      this._userInfo = this.fetchUserInfo();
      await this._userInfo;
      return true;
    } catch (e) {
      if (e.status === 401) {
        return false;
      } else {
        throw e;
      }
    }
  }

  fetchUserInfo = (): Promise<UserInfo> => {
    return this.httpClient.get<UserInfo>(this.path)
      .toPromise();
  }

  activateUser = (resource: UserActivationResource) => {
    return this.httpClient.post<void>(`${this.path}/_activate`, resource)
      .toPromise()
      .catch(err => {
        throw err.error;
      });
  }

  sendPasswordResetMail = (resource: ForgottenPasswordResource) => {
    return this.httpClient.post<void>(`${this.path}/_sendPasswordResetMail`, resource)
      .toPromise()
      .catch(err => {
        throw err.error;
      });
  }

  resetPassword = (resource: PasswordResetResource) => {
    return this.httpClient.post<void>(`${this.path}/_resetPassword`, resource)
      .toPromise()
      .catch(err => {
        throw err.error;
      });
  }

  disconnect = () => {
    return this.httpClient.get<void>('/logout')
      .toPromise()
      .catch(err => {
        throw err.error;
      });
  }

}
