<div class="user-activation-container">
  <h2>Activation de votre compte</h2>
  <mat-card class="user-activation-card" [formGroup]="form">
    <ng-container *ngIf="!activationSuccess">
      <p>Veuillez renseigner et confirmer votre mot de passe, puis cliquer sur valider.</p>
      <mat-form-field>
        <input type="password" matInput placeholder="Mot de passe" formControlName="password">
      </mat-form-field>
      <mat-form-field>
        <input type="password" matInput placeholder="Confirmation mot de passe" formControlName="passwordRepeat"
               (keyup.enter)="form.valid && onSubmit()">
      </mat-form-field>
      <button *ngIf="!submitting" mat-flat-button color="primary" (click)="onSubmit()" [disabled]="form.invalid">Valider</button>
      <p class="failure-message" *ngIf="activationFailure">L'utilisateur que vous souhaitez activer n'existe pas ou le jeton d'activation n'est plus valide.</p>
      <mat-spinner [diameter]="16" *ngIf="submitting"></mat-spinner>
    </ng-container>
    <p *ngIf="activationSuccess">Votre compte a été activé. Veuillez cliquer <span class="link" routerLink="connexion">ici pour vous connecter</span>.</p>
  </mat-card>
</div>
