import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { UserService } from '../shared/service/user.service';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss']
})
export class UserActivationComponent implements OnInit {

  submitting = false;
  activationToken: string;
  form: FormGroup;
  activationSuccess = false;
  activationFailure = false;

  constructor(route: ActivatedRoute, private userService: UserService) {
    this.activationToken = route.snapshot.params.activationToken;
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required]),
      passwordRepeat: new FormControl('', [Validators.required]),
    }, this.validatePasswords);
  }

  validatePasswords: ValidatorFn = group => {
    const value = group.value as {password: string, passwordRepeat: string};
    if (value.password !== value.passwordRepeat) {
      return { mismatch: true };
    }
    return null;
  }

  onSubmit = async () => {
    this.submitting = true;
    this.activationSuccess = false;
    this.activationFailure = false;
    try {
      await this.userService.activateUser({
        activationToken: this.activationToken,
        password: this.form.value.password as string
      });
      this.activationSuccess = true;
    } catch (err) {
      this.activationFailure = true;
    } finally {
      this. submitting = false;
    }
  }

  ngOnInit(): void {
  }

}
