<div class="login-container">
  <h2>Bienvenue sur APEE</h2>
  <div class="content">
    <div class="description">
      <div>APEE est l'acronyme pour <span class="accronym-first-letter">A</span>ssistant de <span class="accronym-first-letter">P</span>lanification des <span class="accronym-first-letter">É</span>valuations en <span class="accronym-first-letter">E</span>rgothérapie.</div>
      <div>Ce portail est dédié à la gestion et au suivi des évaluations à domicile en Ergothérapie.</div>
    </div>
    <mat-card class="login-card" [formGroup]="form">
      <mat-form-field>
        <input matInput placeholder="Identifiant" formControlName="username" (keyup.enter)="onSubmit()">
      </mat-form-field>
      <mat-form-field>
        <input type="password" matInput placeholder="Mot de passe" formControlName="password" (keyup.enter)="onSubmit()">
      </mat-form-field>
      <button *ngIf="!submitting" mat-flat-button color="primary" (click)="onSubmit()">Connexion</button>
      <a class="forgotten-password-link" routerLink="/mot-de-passe-oublie" href="#">Mot de passe oublié ?</a>
      <mat-spinner [diameter]="16" *ngIf="submitting"></mat-spinner>
      <div class="footer">
        Vous êtes un partenaire d'Axergo Habitat et n'avez pas encore vos accès, <br /> contactez-nous par mail à <a href="mailto:contact@axergohabitat.fr">contact@axergohabitat.fr</a> afin de les obtenir.
      </div>
    </mat-card>
  </div>
</div>
