<div class="forgotten-password-container">
  <h2>Mot de passe oublié</h2>
  <mat-card class="forgotten-password-card" [formGroup]="form">
    <ng-container *ngIf="!sendMailSuccess">
      <p>Veuillez renseigner l'adresse e-mail liée à votre compte et cliquer sur valider.</p>
      <mat-form-field>
        <input matInput placeholder="Adresse e-mail" formControlName="mail">
      </mat-form-field>
      <button *ngIf="!submitting" mat-flat-button color="primary" (click)="onSubmit()" [disabled]="form.invalid">Valider</button>
      <p class="failure-message" *ngIf="sendMailFailure">Une erreur est survenue lors de l'envoi de votre adresse e-mail.</p>
      <mat-spinner [diameter]="16" *ngIf="submitting"></mat-spinner>
    </ng-container>
    <p *ngIf="sendMailSuccess">Un e-mail vous permettant de réinitialiser votre mot de passe a été envoyé à l'adresse indiquée.</p>
  </mat-card>
</div>
